<template>
  <div class="tfa_confgure">
    <v-container fluid>
      <div v-if="logged_in">
        <v-alert v-if="$route.query.redirect" dark color="warning"
          >Om deze pagina te bezoeken is Multi factor authenticatie verplicht!
        </v-alert>
        <div v-if="tfa_configured === false">
          <v-row>
            <v-col><h2>Multi factor authenticatie instellen</h2> </v-col>
          </v-row>
          <v-row>
            <v-col sm="8">
              <v-row>
                <v-col>
                  <v-card class="">
                    <v-card-text>
                      <p>
                        Met two-factor authentication voeg je extra beveiliging
                        toe aan je account door met een extra code in te loggen.
                        Deze kun je aanmaken met een Authenticator app zoals
                        Google Authenticator of Windows Authenticator, of Twilio
                        Authy.
                      </p>

                      <p>Two-factor authentication stel je als volgt in:</p>
                      <ul>
                        <li>Installeer een authenticator app</li>
                        <li>Scan de QR-code die verschijnt op deze pagina</li>
                        <li>
                          Vul de gegenereerde verificatiecode in op deze pagina
                          en klik op
                          <a href="#" @click.prevent="activate2FA"
                            >MFA Activeren
                          </a>
                        </li>
                      </ul>
                    </v-card-text></v-card
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-title>
                      MFA activeren
                    </v-card-title>
                    <form @submit.prevent="activate2FA">
                      <v-card-text>
                        <v-text-field
                          label="Secret"
                          :value="new_secret"
                          readonly=""
                          disabled=""
                        >
                        </v-text-field>
                        <v-text-field
                          v-model="otp"
                          autocomplete="one-time-code"
                          label="Vul hier de verificatiecode in"
                          :error="invalid_token"
                        >
                        </v-text-field>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn type="submit" color="success " block
                          >MFA Activeren</v-btn
                        >
                      </v-card-actions>
                    </form>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="4" xs="12">
              <img class="qr_image" :src="qr_image" />
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row>
            <v-col><h2>Multi factor authenticatie</h2> </v-col>
          </v-row>
          <v-alert prominent icon="check" dark color="success">
            <v-row align="center">
              <v-col class="grow">MFA is actief </v-col>
              <v-col v-if="$route.query.redirect" class="shrink">
                <v-btn :to="$route.query.redirect">Ga verder</v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <v-row v-if="!$route.query.redirect">
            <v-col>
              <v-card class="">
                <v-card-title
                  >Multi factor authenticatie uitschakelen
                </v-card-title>
                <form @submit.prevent="deactivate2FA">
                  <v-card-text>
                    <p>
                      Bevestig hieronder dat je MFA wilt uitschakelen met een
                      MFA verificatiecode. Geen toegang meer tot MFA apperaat?
                      neem contact met ons op. Let op dat bepaalde functies
                      mogelijk niet beschikbaar zijn zonder MFA.
                    </p>
                    <v-text-field
                      v-model="otp_delete"
                      autocomplete="one-time-code"
                      label="Vul hier de verificatiecode in"
                      :error="invalid_token"
                    >
                    </v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn type="submit" color="red" dark block>
                      MFA Deactiveren
                    </v-btn>
                  </v-card-actions>
                </form>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ConfigureTFA",
  data() {
    return {
      new_secret: null,
      tfa_configured: null,
      qr_url: null,
      otp: "",
      otp_delete: "",
      invalid_token: false,
    };
  },
  computed: {
    qr_image() {
      return this.axios.defaults.baseURL + "tfa" + this.qr_url;
    },
  },
  watch: {
    otp() {
      this.invalid_token = false;
    },
    otp_delete() {
      this.invalid_token = false;
    },
  },
  mounted() {
    this.axios
      .get("tfa")
      .then((res) => {
        this.tfa_configured = res.data.tfa_configured;
        this.new_secret = res.data.new_secret || null;
        this.qr_url = res.data.qr_url || null;
      })
      .catch(() => {});
  },
  methods: {
    activate2FA() {
      this.axios
        .post("tfa", {
          otp: this.otp,
          secret: this.new_secret,
        })
        .then((res) => {
          this.tfa_configured = res.data.tfa_configured;
          this.new_secret = res.data.new_secret || null;
          this.qr_url = res.data.qr_url || null;
        })
        .catch(() => {
          this.invalid_token = true;
        });
    },
    deactivate2FA() {
      this.axios
        .delete("tfa", {
          data: {
            otp: this.otp_delete,
          },
        })
        .then((res) => {
          this.tfa_configured = res.data.tfa_configured;
          this.new_secret = res.data.new_secret || null;
          this.qr_url = res.data.qr_url || null;
        })
        .catch(() => {
          this.invalid_token = true;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.qr_image {
  max-width: 100%;
}
</style>
